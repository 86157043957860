import React, { useState } from 'react';
import { RiMenu3Fill } from 'react-icons/ri'
import { Link, useLocation } from 'react-router-dom';
import NavBarOpen from './NavBarOpen';
import { motion } from 'framer-motion'

function UserNavBar({ categories }){

    const [navbarToggle, setNavbarToggle] = useState(false);
    const location = useLocation();

    return(
        <nav className={location.pathname === "/" ? '' : 'user-nav'}>
            <div className='user-nav-content'>
            {location.pathname === "/" 
                ? <p> </p> 
                : <Link to='/'>
                    <motion.img whileHover={{scale:1.1}} className='nav-logo' src='/images/ii_logo_black_short.png' alt='company logo'/>
                </Link>
                  }
            <motion.p className={location.pathname === "/" ? 'burger burger-light m-0' : 'burger burger-dark m-0'} onClick={() => setNavbarToggle(true)}
            whileHover={{scale: 1.1}}>
                <RiMenu3Fill/>
            </motion.p>
            </div>

            <NavBarOpen categories={categories} setNavbarToggle={setNavbarToggle} navbarToggle={navbarToggle}/>
        </nav>
    )
}

export default UserNavBar;