import React from 'react';
import { Switch, Route } from 'react-router';
import useFireStore from '../Firebase/useFirestore';
import PhotoContainer from '../Photos/PhotoContainer';
import Contact from './Contact';
import PageNotFound from './PageNotFound';
import UserHome from './UserHome';
import UserNavBar from './UserNavBar';

function UserContainer(){

    const {images, categories } = useFireStore();

    
    return(
        <>
            <UserNavBar categories={categories}/>
            <Switch>

                <Route exact path='/category/:category'>
                    <PhotoContainer images={images} />
                </Route>

                <Route path='/contact'>
                    <Contact/>
                </Route>

                <Route exact path='/'>
                    <UserHome />
                </Route>

                <Route path='/'>
                    <PageNotFound/>
                </Route>

            </Switch>
        </>
    )
}

export default UserContainer;