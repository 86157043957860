import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PhotoItemButtons from './PhotoItemButtons';
import { useAuth } from '../Firebase/authContext';
import {FiArrowRight, FiArrowLeft, FiX} from 'react-icons/fi'
import { AnimatePresence, motion } from 'framer-motion';

function PhotoModal({photoModal, setPhotoModal, selectedPhoto, setSelectedPhoto, setEditPhotoModal, handleKeyPress, selectedPhotoIndex, nextPhoto, prevPhoto, imageCount}){

    function handleHideModal(){
        setPhotoModal(false);
        setSelectedPhoto(null)
    }
    console.log('renderedx  ')

    const { currentUser } = useAuth();
    

    if(photoModal === true){
    return(

        <div onKeyUp={handleKeyPress}>
        <Modal show={photoModal} onHide={handleHideModal} centered dialogClassName="modal-95w" contentClassName='modal-opacity'>
             <div className='col'>
                <p className='modal-close m-0' onClick={handleHideModal}><FiX/></p>
                {selectedPhotoIndex === 0
                    ? null
                    : <p onClick={prevPhoto} className='arrow arrow-left'><FiArrowLeft/></p>
                }
                {(selectedPhotoIndex < imageCount - 1)
                    ? <p onClick={nextPhoto} className='arrow arrow-right'><FiArrowRight/></p>
                    : null
                }

                 <div className='pic-container justify-content-center text-center'>
                    <img className='pic-modal' src={selectedPhoto.picUrl} alt='selected modal' />
                 </div>


             </div>
             <div className='col text-left modal-text pt-3 pb-1'>
                 <div className='d-flex justify-content-between'>
                    <h2>{selectedPhoto.title}</h2>
                    {currentUser && <PhotoItemButtons selectedPhoto={selectedPhoto} setSelectedPhoto={setSelectedPhoto} setEditPhotoModal={setEditPhotoModal} setPhotoModal={setPhotoModal}/> }
                 </div>
                 <p>{selectedPhoto.description}</p>
             </div>
  
      </Modal>
      </div>

    )} else{
        return null;
    }
}

export default PhotoModal;