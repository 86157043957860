import React from 'react';
import CategoryItem from './CategoryItem';

function CategoryList( { sortedCategories }){

    const categoryItems = sortedCategories.map((category) => {
        return (
            <CategoryItem category={category.category} pictures={category.pictures} id={category.id} key={category.id}/>
        )
    })

    return(
        <div className='row text-center '>
            { categoryItems }
        </div>
    )
}

export default CategoryList;