import React from'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AdminContainer from './Admin/AdminContainer';
import UserContainer from './User/UserContainer';
import { AuthProvider } from './Firebase/authContext';


function App() {
  return (
    <Router>
      <AuthProvider>
      <Switch>
        <Route path="/admin" component={AdminContainer}/>
        <Route path="/" component={UserContainer}/>
      </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
