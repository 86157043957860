import React from 'react'
import PhotoItem from './PhotoItem';
import {motion} from 'framer-motion'

function PhotoItemContainer({image, setPhotoModal, setSelectedPhoto, photoIndex, setSelectedPhotoIndex}){

    return(
        <motion.div className='pic-item'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{opacity: {duration: 2},
                    delay: 0.3}}>
            <PhotoItem image={image} setPhotoModal={setPhotoModal} setSelectedPhoto={setSelectedPhoto} photoIndex={photoIndex} setSelectedPhotoIndex={setSelectedPhotoIndex}/>
        </motion.div>
    )
}

export default PhotoItemContainer;