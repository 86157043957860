import React from 'react';
import CategoryContainer from '../Category/CategoryContainer';
import UploadForm from './UploadForm';

function Home({ categories, images}){


    return(
        <div className=''>
          <UploadForm categories={categories} />
          <CategoryContainer images={images} categories={categories} /> 
        </div>
    )
}

export default Home;