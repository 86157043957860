import { useEffect, useState } from 'react';
import { firebaseFirestore } from '../Firebase/config';

const useFireStore = () => {
    const [images, setImages] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const unsub = firebaseFirestore.collection('images')
          .orderBy('createdAt', 'desc')
          .onSnapshot(snap => {
            let documents = [];
            snap.forEach(doc => {
              documents.push({...doc.data(), id: doc.id});
            });
            setImages(documents);
          });
    
        return () => unsub();
      }, []);
  
      useEffect(() => {
        const unsub = firebaseFirestore.collection('categories')
          .orderBy('category')
          .onSnapshot(snap => {
            let documents = [];
            snap.forEach(doc => {
              documents.push({id: doc.id, category: doc.data().category});
            });
            setCategories(documents);
          });
    
        return () => unsub();
      }, []);

      return { images, categories}
}

export default useFireStore;