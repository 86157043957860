import React from 'react';
import {Link} from 'react-router-dom';
import { firebaseFirestore } from '../Firebase/config';

function CategoryItem({ category, pictures, id}){

    const imgsrc = (pictures.length > 0) ? pictures[0].picUrl : '/images/nophoto.png'
    const url = `/admin/${category}`;

    function  handleCategoryDelete(){
        firebaseFirestore.collection("categories").doc(id).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }

    return(
        <div className='col-lg-4 col-md-6 my-2'>
            <div className='imgContainer'>
                <Link to={url}>
                    <img src={imgsrc} className='img-fluid cat-image' alt='sample image for category'/>
                </Link>
            </div>
                <h4 className='mt-1 fw-800'>{category}</h4>
                <p>Photos in Category: {pictures.length} </p>
                {pictures.length > 0
                    ? <Link to={url} ><button className='btn btn-primary'>View photos</button></Link>
                    : <button className='btn btn-primary' onClick={handleCategoryDelete}>Delete Category</button>
                }

        </div>
    )
}

export default CategoryItem;