import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { AuthProvider } from '../Firebase/authContext';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import LoggedInAdmin from './LoggedInAdmin';

function AdminContainer(){

    return(
        <>

                <Switch>
                    <Route path="/admin/login" component={Login}/>
                    <PrivateRoute path="/admin" component={LoggedInAdmin}/>

                </Switch>

        </>
    )
}

export default AdminContainer;