import React, { useState } from 'react';
import { useParams } from 'react-router';
import PhotoModal from '../Modals/PhotoModal';
import EditPhotoModal from '../Modals/EditPhotoModal';
import PhotoItemContainer from './PhotoItemContainer';
import Footer from '../User/Footer';

function PhotoContainer({images}){

    const {category} = useParams()
    const [photoModal, setPhotoModal] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [editPhotoModal, setEditPhotoModal] = useState(false)
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);

    const imagesFiltered = images.filter(image => image.category === category)
    const photoComponents = imagesFiltered.map((image, index) => {
        return (
                <PhotoItemContainer image={image} key={image.id} setPhotoModal={setPhotoModal} setSelectedPhoto={setSelectedPhoto} photoIndex={index} setSelectedPhotoIndex={setSelectedPhotoIndex}/>
        )
    })

    function handleKeyPress(e){
        console.log(e.key)
        if(e.key === 'ArrowRight' && selectedPhotoIndex < imagesFiltered.length - 1){
            nextPhoto()
        }
        if(e.key === 'ArrowLeft' && selectedPhotoIndex > 0){
            prevPhoto()
        }
    }

    function nextPhoto(){
        setSelectedPhoto(imagesFiltered[selectedPhotoIndex + 1])
        setSelectedPhotoIndex(selectedPhotoIndex + 1)
    }

    function prevPhoto(){
        setSelectedPhoto(imagesFiltered[selectedPhotoIndex - 1])
        setSelectedPhotoIndex(selectedPhotoIndex - 1)
    }

    


    
    return(
        <div className='content-container'>
            <h2 className='main-heading'>{category}</h2>

            <div className='pic-gallery'>
                {photoComponents}
            </div>

            {photoModal
            ? <PhotoModal onKeyUp={handleKeyPress} selectedPhoto={selectedPhoto} setSelectedPhoto={setSelectedPhoto} photoModal={photoModal} setPhotoModal={setPhotoModal} editPhotoModal={editPhotoModal} setEditPhotoModal={setEditPhotoModal} handleKeyPress={handleKeyPress} selectedPhotoIndex={selectedPhotoIndex} nextPhoto={nextPhoto} prevPhoto={prevPhoto} imageCount={imagesFiltered.length}/>
            : null
            }
            
            {editPhotoModal 
            ? <EditPhotoModal selectedPhoto={selectedPhoto} setSelectedPhoto={setSelectedPhoto} editPhotoModal={editPhotoModal} setEditPhotoModal={setEditPhotoModal}/>
            : null
            }

            <Footer/>
        </div>

    )
}

export default PhotoContainer;