import React from 'react';
import {FiX} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion'

function NavBarOpen({ categories, setNavbarToggle, navbarToggle }){

    const navOptions = categories.map(category => {
        return (
                <li className='nav-list-item' key={category.id}>
                    <Link onClick={handleNavToggle} to={`/category/${category.category}`} className='nav-link'>
                        {category.category}
                    </Link>
                </li>
        )
    })

    function handleNavToggle(){
        setNavbarToggle(false);
    }

    return(
        <AnimatePresence>
        { navbarToggle && (
            <motion.div className='backdrop' onClick={handleNavToggle}
            key="navbar"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}>

                <motion.div className='nav-open'
                initial={{x: 100}}
                animate={{x: 0}}
                exit={{x: 200}}>

                    <motion.p className='close-nav-button' onClick={handleNavToggle}
                    whileHover={{scale:1.1}}>
                        <FiX/>
                    </motion.p>

                    <div className='nav-list-container'>
                        
                        <ul className='nav-list'>
                            <h3 className='nav-list-header'>Galleries</h3>
                            {navOptions}
                            <hr className='nav-break'></hr>
                            <Link to='/contact' className='nav-list-item nav-link'>
                                Contact
                            </Link>
                        </ul>
                    </div>
                </motion.div>
            </motion.div>
         )}
        </AnimatePresence>
    )
}

export default NavBarOpen;