import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../Firebase/authContext';


function AdminNavBar(){

    const { logout } = useAuth()

    function handleLogOut(){
        logout()
    }

    return(
        <nav className='navbar navbar-dark bg-primary'>
            <Link to='/admin'><p className='navbar-brand h2 mb-0'>Image Inception</p></Link>
            <button className='btn btn-outline-light' onClick={handleLogOut}>Log Out</button>
        </nav>
    )
}

export default AdminNavBar;