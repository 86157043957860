import React, {useState, useRef} from 'react';
import { useAuth} from '../Firebase/authContext';
import { useHistory } from 'react-router-dom';


function Login(){

    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()


    async function handleSubmit(e) {
        e.preventDefault()
        try {
          setError("")
          setLoading(true)
          await login(emailRef.current.value, passwordRef.current.value)
          setLoading(false)
          history.push("/admin")
        } catch(error) {
          setError("Failed to log in")
          setLoading(false)
        }
      }

    return(
        <>
            <h2>Log In</h2>
            <p>{error}</p>

            <form onSubmit={handleSubmit}>
                <label htmlFor='email'>Email</label>
                <input type='email' name='email' id='email' ref={emailRef}/>

                <label htmlFor='password'>Password</label>
                <input type='password' name='password' id='password' ref={passwordRef}/>

                <button type='sumbit' disabled={loading}>Log In</button>
            </form>
        </>
    )

}


export default Login;