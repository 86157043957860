import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { firebaseFirestore } from '../Firebase/config';

function EditPhotoModal({selectedPhoto, setSelectedPhoto, editPhotoModal, setEditPhotoModal}){

    const [formDetails, setFormDetails] = useState({
        title: selectedPhoto.title,
        description: selectedPhoto.description
    })

    function handleFormChange(e){
        setFormDetails({...formDetails, [e.target.name]: e.target.value})
    }

    function handleHideModal(){
        setEditPhotoModal(false)
        const newSelectedPhoto = {...selectedPhoto, ...formDetails}
        setSelectedPhoto(newSelectedPhoto)
    }

    function handleFormSave(){
        firebaseFirestore.collection('images').doc(selectedPhoto.id).update({
            title: formDetails.title,
            description: formDetails.description
        }).then(() => {
            console.log("Document successfully updated!");
            handleHideModal()
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
        });
    }

    if(editPhotoModal === true){
    return(
        <Modal show={editPhotoModal} onHide={handleHideModal}>
            <Modal.Header closeButton>
            <Modal.Title>Edit Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor='title' className='form-label'>Title</label>
                <input  type='text' name='title' id='title' value={formDetails.title} onChange={handleFormChange} required className='form-control'/>
                <label htmlFor='description' className='form-label'>Description</label>
                <textarea rows="4" name='description' id='description' value={formDetails.description} onChange={handleFormChange} className='form-control'/>
                
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleFormSave} className='btn btn-primary'>Save</button>
                <button onClick={handleHideModal} className='btn btn-primary'>Cancel</button>
            </Modal.Footer>
        </Modal>
    )} else {
        return null;
    }
}

export default EditPhotoModal;
