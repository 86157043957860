import React from 'react';
import { motion } from 'framer-motion';
import useWindowDimensions from '../Helper/useWindowSize';

function UserHome(){

    const { width } = useWindowDimensions();
    console.log(width)

    return(
        <motion.div className='home-container'>
                <div className='home-text'>
                    <motion.img className='ii-logo'
                    src={ width > 800 ? '/images/ii_logo_white_long.png' : '/images/ii_logo_white_tall.png' }
                    alt='company logo'
                    initial={{x: '-100vw'}}
                    animate={{x: 0}}
                    transition={{ duration: 1,
                    delay: 0.8}}/>
                    <motion.p
                    initial={{x: '100vw'}}
                    animate={{x: 0}}
                    transition={{ duration: 1, delay: 1.4}}>
                        Photography by Iain Moffat
                    </motion.p>
                </div>


        </motion.div>
    )
}

export default UserHome;