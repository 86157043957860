import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { TiTickOutline } from 'react-icons/ti';
import { BiErrorAlt } from 'react-icons/bi';
import { FiSend } from 'react-icons/fi';

function Contact(){

    const [form, setForm] = useState({
        name: '',
        email: '',
        message: ''
    })

    const [sentMessage, setSentMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)

    const updateField = (e) => {
        setForm({...form, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(null);
        emailjs.sendForm(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, '#contact_form', 
        process.env.REACT_APP_EMAIL_JS_USER_ID)
            .then((result) => {
                setSentMessage(true)
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            })
    }

    return(
        <div className='form-container'>
            
            { sentMessage 
                ?
                <div className='text-center'>
                    <p className='tick-icon'><TiTickOutline/></p>
                    <p className='sent-message'>Thanks for the message</p>
                    <p className='sent-message'>I will get back to you as soon as possible</p>
                </div>

                :
                <>
                <h2 className='text-center main-heading'>Get In Touch</h2>
                <form id='contact_form' onSubmit={handleSubmit}>
                    <label className='form-label' htmlFor='name'>Name</label>
                    <input className='form-control' type='text' name='name' id='name' value={form.name} onChange={updateField}/>

                    <label className='form-label' htmlFor='email'>Email</label>
                    <input className='form-control' type='email' name='email' id='email' value={form.email} onChange={updateField}/>

                    <label className='form-label' htmlFor="message">Message</label>
                    <textarea className='form-control' name="message" id="message" value={form.message} onChange={updateField} rows="4"/>

                    <button className='form-button' type='submit'>Send <FiSend/></button>
                    {errorMessage && <p className='text-center error-message'><BiErrorAlt/><BiErrorAlt/> Sorry, there seems to be problem <BiErrorAlt/><BiErrorAlt/><br/> Please try again later</p>}
                </form>
                </>
            }
           
        </div>
    )
}

export default Contact;