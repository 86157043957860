import React from 'react';

function Footer(){

    return(
        <footer className='text-center'>
            <p className=''>
                Copyright &copy; 2021 - Inception Images
            </p>
        </footer>
    )
}

export default Footer;