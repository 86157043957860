import React from 'react';


function PhotoItem({image, setSelectedPhoto, setPhotoModal, photoIndex, setSelectedPhotoIndex}){

    function handleOpenPhoto(){
        setSelectedPhoto(image);
        setPhotoModal(true);
        setSelectedPhotoIndex(photoIndex)
    }


    return(
        <img className='hover-cursor' src={image.picUrl} alt='brief description' onClick={handleOpenPhoto}/>

    )
}

export default PhotoItem;