import AddCategoryModal from '../Modals/AddCategoryModal';
import React, { useState } from 'react';
import { firebaseFirestore, firebaseStorage, timestamp } from '../Firebase/config';
import { BiError } from 'react-icons/bi';


function UploadForm({ categories }) {

    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const fileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const [fileDetails, setFileDetails] = useState({
        title: '',
        description: '',
        category: ''
    })
    const [categoryModal, setCategoryModal] = useState(false);

    function handleFileChange(e){
        let selected = e.target.files[0];
        if( selected && fileTypes.includes(selected.type)){
            setFile(selected)
            setFileError('')
        } else {
            setFile(null);
            setFileError('Please make sure file type is jpeg, jpg or png')
        }
    }

    function handleFormChange(e){
        setFileDetails({...fileDetails, [e.target.name]: e.target.value})
    }

    function resetForm(){
        setFile(null);
        setProgress(0);
        setIsLoading(false);
        setFileDetails({
            title: '',
            description: '',
            category: ''
        })
    }

    function handleSubmit(e){
        e.preventDefault()

        if(fileDetails.category && file){
            setErrorMessage(null)
            const storageRef = firebaseStorage.ref(file.name);
            const collectionRef = firebaseFirestore.collection('images');

            setIsLoading(true);

            storageRef.put(file).on('state_changed', (snapshot) => {
                let uploadPercentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 90;
                setProgress(uploadPercentage);
            }, (err) => {
                setErrorMessage(err);
            }, async () => {
                const picUrl = await storageRef.getDownloadURL();
                const createdAt = timestamp();
                await collectionRef.add({...fileDetails, picUrl, createdAt, storageReference: file.name});
                setProgress(100);
                resetForm();
            })
        }else{
            setErrorMessage('Please make sure that a file & category are selected')
        }
    }

    function handleCategoryModal(e){
        setCategoryModal(true);
    }

    function addNewCategory(newCategory){
        const categoryRef = firebaseFirestore.collection('categories');
        categoryRef.add({category: newCategory})
            .then(console.log('Category Added Successfully'))
            .catch((error) => {
                console.log(error)
            })
    }


    return(
        <div className='container-fluid'>
            <div className='row mt-4 justify-content-center'>
                
                <div className='col-11 col-md-8'>

                    <div className='row'>
                        <div className='col'>
                            <div className='col py-2 d-flex align-items-center'>
                                <label className='btn btn-primary mb-0'> Choose File
                                <input className='file-input' type='file' onChange={handleFileChange}/>
                                </label>
                                <div className='px-3'>
                                {!file && !fileError && <p className='m-0'>No file selected</p> }
                                {file && <p className='m-0'>{file.name}</p> }
                                {!file && fileError && <p className='m-0 error-message'><BiError/> {fileError}</p>}
                                </div>
                            </div>

                            <div className='col py-2'>
                                <label htmlFor='title' className='form-label'>Title</label>
                                <input  type='text' name='title' id='title' value={fileDetails.title} onChange={handleFormChange} required className='form-control'/>
                            </div>
                            <div className='col py-2'>
                                <label htmlFor='description' className='form-label'>Description</label>
                                <textarea rows="4" name='description' id='description' value={fileDetails.description} onChange={handleFormChange} className='form-control'/>
                            </div>

                            <div className='col py-2'>
                                <label htmlFor='category' className='form-label pr-3'>Category</label>
                                <select name='category' id='category' value={fileDetails.category} onChange={handleFormChange} className='form-select'>
                                    <option value="" disabled>Please Select</option>
                                {categories.map((option) => (
                                    <option value={option.category} key={option.id}>{option.category}</option>
                                ))}
                                </select>
                                <button className='btn btn-secondary ml-3 py-0' onClick={handleCategoryModal}>Add Category</button>
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-center mt-3'>
                        {isLoading
                            ? <button disabled className='btn btn-primary col-11 col-md-6'>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="visually-hidden">Loading...</span>
                            </button>
                            : <button onClick={handleSubmit} className='btn btn-primary col-11 col-md-6'>Upload</button>
                        }
                        {errorMessage && <p className='m-0 error-message'><BiError/> {errorMessage}</p>}
                    </div>

                </div>

                <AddCategoryModal categoryModal={categoryModal} setCategoryModal={setCategoryModal} addNewCategory={addNewCategory}/>

            </div>
        </div>
    )
}

export default UploadForm;