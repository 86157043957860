import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminNavBar from './AdminNavBar';
import AdminHome from './AdminHome';
import useFireStore from '../Firebase/useFirestore';
import PhotoContainer from '../Photos/PhotoContainer';

function LoggedInAdmin(){

    const { images, categories } = useFireStore();

    return(
        <>
            <AdminNavBar/>
            <Switch>
              <Route path='/admin/:category'>
                <PhotoContainer images={images} />
              </Route>
              <Route path='/admin'>
                <AdminHome categories={categories} images={images}/>
              </Route>

            </Switch>
        </>
    )
  }

  
export default LoggedInAdmin;