import React from 'react'
import { firebaseFirestore, firebaseStorage } from '../Firebase/config';

function PhotoItemButtons({selectedPhoto, setSelectedPhoto, setEditPhotoModal, setPhotoModal}){
    


    function handleDeletePhoto(){
        firebaseFirestore.collection("images").doc(selectedPhoto.id).delete()
            .then(() => {
                const storageRef = firebaseStorage.ref(selectedPhoto.storageReference);
                storageRef.delete()
            })
            .then(() => {
                setPhotoModal(false)
                setSelectedPhoto(null)
                console.log("Document successfully deleted!");
            }).catch((error) => {
                console.error("Error removing document: ", error);
            })
    }

    function handleEditPhoto(){
        setEditPhotoModal(true);
        
    }

    return(
        <div>
            <button className='btn btn-light mx-3' onClick={handleEditPhoto}>Edit</button>
            <button className='btn btn-light ' onClick={handleDeletePhoto}>Delete</button>
        </div>
    )
}

export default PhotoItemButtons;