import React, { useEffect, useState } from 'react';
import CategoryList from './CategoryList';

function CategoryContainer({ images, categories }){

    const [sortedCategories, setSortedCategories] = useState([]);

    useEffect(() => {

        let tempArr = categories.map((category) => {
            let newObj = {category: category.category, pictures: [], id: category.id};

            for(let image of images){

                if(image.category === newObj.category){
                    newObj.pictures.push(image)
                }
            }
            return newObj;
        })

        setSortedCategories(tempArr)

    }, [images, categories]);


    return(
        <div className='container text-center my-5'>
            <h1 className='p-2 fw-800'>Categories</h1>
            <CategoryList sortedCategories={sortedCategories}/>
        </div>
    )
}

export default CategoryContainer;