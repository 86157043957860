import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function AddCategoryModal( { categoryModal, setCategoryModal, addNewCategory }){

    const [category, setCategory] = useState("");

    function handleFormChange(e){
        setCategory(e.target.value)
    }

    function handleAddCategory(){
        addNewCategory(category);
        setCategory('')
    }

    return(
        <Modal show={categoryModal} onHide={()=> setCategoryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <label htmlFor='category-title' className='form-label'>
                Category Title
            </label>
            <input className='form-control' name='category-title' id='category-title' value={category} onChange={handleFormChange} />
        </Modal.Body>
        <Modal.Footer>
            <button className='btn btn-primary' onClick={handleAddCategory}>Add New Category</button>
        </Modal.Footer>
      </Modal>
    )
}

export default AddCategoryModal;